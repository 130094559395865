import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'woowtech-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title = 'Confirmar'; // TODO: Mockado
  @Input() message = 'Tem certeza que deseja executar essa ação?'; // TODO: Mockado
  @Output() confirm = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  close(reponse?: boolean) {
    this.dialogRef.close(reponse);
  }
}
