import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { LoginData } from '../models/user.model';
import { tap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _env = environment;

  constructor(private http: HttpClient, private userService: UserService) {}

  public authenticate(data: LoginData): Observable<string> {
    const setToken = (token: string) => this.setToken(token);
    return this.http
      .post<string>(`${this._env.api}/users/authenticate`, data)
      .pipe(tap(setToken));
  }

  public resetPassword(data: LoginData): Observable<any> {
    return this.http.post(`${this._env.api}/users/email`, data);
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    const jwtHelper = new JwtHelperService();
    return token && !jwtHelper.isTokenExpired(token);
  }

  public setToken(token: string) {
    localStorage.setItem(this._env.storage.token, token);
  }

  public getToken() {
    return localStorage.getItem(this._env.storage.token);
  }

  public logout() {
    localStorage.removeItem(this._env.storage.token);
    this.userService.removeUserFromStorage();
  }
}
