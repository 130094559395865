<div class="dialog-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    <mat-selection-list
      [multiple]="false"
      [(ngModel)]="itemSelected"
      (ngModelChange)="onChangeItem($event)"
    >
      <mat-list-option *ngFor="let item of data.list" [value]="item.Id">
        {{ item.Name }}
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="close()">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</div>
