<div class="round-icon-button">
  <div class="row">
    <button mat-mini-fab class="mat-elevation-z0" [style]="{
      'background-color': color
    }">
      <span class="icon" [class]="icon"></span>
    </button>
  </div>
  <div class="row">
    <span>{{ label }}</span>
  </div>
</div>  