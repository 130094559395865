import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListOptionsData } from './list-options.model';

@Component({
  selector: 'woowtech-list-options',
  templateUrl: './list-options.component.html',
  styleUrls: ['./list-options.component.scss']
})
export class ListOptionsComponent {

  public itemSelected;

  constructor(
    public dialogRef: MatDialogRef<ListOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ListOptionsData
  ) { }

  public close(reponse?: boolean) {
    this.dialogRef.close(reponse);
  }

  public onChangeItem(arrId: string[]) {
    this.dialogRef.close(arrId[0]);
  }
}
