import { Component, Input } from '@angular/core';

@Component({
  selector: 'woowtech-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() initials: string;
  @Input() size = '60px';
  @Input() circleColor = 'var(--light-grey-1)';
  @Input() initialsColor = 'var(--light-grey-2)';

  constructor() {}
}
