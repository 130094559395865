<div class="confirm-container">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="close()">
      {{ 'Cancel' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusInitial
      (click)="close(true)"
    >
      {{ 'Confirm' | translate }}
    </button>
  </div>
</div>
