import { Component } from '@angular/core';

@Component({
  selector: 'woowtech-root',
  template: `<router-outlet></router-outlet>`,
  styles: ['']
})
export class AppComponent {
  title = 'portal';
}
