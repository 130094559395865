import { FormsModule } from '@angular/forms';
import { ListOptionsComponent } from './list-options.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [ListOptionsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    MatListModule,
    FormsModule
  ],
  exports: [ListOptionsComponent]
})
export class ListOptionsModule { }
