import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { User, UserPermission } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly _env = environment;

  constructor(private http: HttpClient) {}

  public getUsers(): Observable<any> {
    return this.http.get(`${this._env.api}/users`);
  }

  public getCurrentUser(): Observable<User> {
    const currentUser = this.getUser();
    if (currentUser) {
      return of(JSON.parse(currentUser));
    }

    // TODO: criar modelo para usuário
    const setUser = (user: any) => this.setUser(user);
    return this.http.get(`${this._env.api}/users/actual`).pipe(tap(setUser));
  }

  public getUserById(id: string): Observable<any> {
    return this.http.get(`${this._env.api}/users/${id}`);
  }

  public save(data): Observable<any> {
    return this.http.post(`${this._env.api}/users/save`, data);
  }

  public delete(id: string): Observable<any> {
    return this.http.delete(`${this._env.api}/users/${id}`);
  }

  public setUser(user) {
    localStorage.setItem(this._env.storage.user, JSON.stringify(user));
  }

  public getUser() {
    return localStorage.getItem(this._env.storage.user);
  }

  public getUserPermission(): UserPermission {
    const user: User = JSON.parse(this.getUser());
    return user.Type;
  }

  public removeUserFromStorage() {
    localStorage.removeItem(this._env.storage.user);
  }
}
