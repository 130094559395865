import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function platformLangFactory() {
  const fallbackLang = 'pt-BR';
  const browserLang =
    localStorage.getItem('lang') || window.navigator.language || fallbackLang;

  return /(en-US|es-AR|es-CL|es-CO|es-ES|pt-BR|pt-PT)/gi.test(browserLang)
    ? browserLang
    : fallbackLang;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `/assets/i18n/`, '.json');
}

export function fillInitials(name: string, textSizeRatio = 1): string {
  if (!name) return;

  let _initials = name
    .split(/[ ,.-]+/)
    .filter(name => name)
    .map(name => name[0])
    .reduce((initials, initial) => initials + initial, '');

  if (_initials.length > textSizeRatio) {
    _initials = _initials.substr(0, textSizeRatio);
  }

  return _initials;
}