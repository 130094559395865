import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private injector: Injector,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const cloned = this.getClone(request, token);

    return next.handle(cloned).pipe(
      catchError(err => {
        const _snackBar = this.injector.get(MatSnackBar);
        let message =
          err && err.error
            ? err.error.Message
            : this.translateService.instant(
                'There was a problem with your request. Please try again.'
              );

        if (err.status == '401') {
          this.authService.logout();
          this.router.navigate(['/']);
        }

        if (err.status == '403') {
          message = this.translateService.instant(
            'You are not allowed to access this functionality.'
          );
          this.router.navigate(['/']);
        }

        _snackBar.open(message, this.translateService.instant('Ok'), {
          duration: 4000
        });

        this.spinner.hide();
        throw err;
      })
    );
  }

  private getClone(request:   HttpRequest<any>, token: string) {
    if (!token) {
      return request;
    }

    const options = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }

    if (!request.body) {
      options['Content-Type'] = 'application/json';
    }

    return request.clone({
      setHeaders: options
    });
  }
}
