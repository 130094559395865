import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'woowtech-round-icon-button',
  templateUrl: './round-icon-button.component.html',
  styleUrls: ['./round-icon-button.component.scss']
})
export class RoundIconButtonComponent implements OnInit {

  @Input() public icon: string;
  @Input() public label: string;
  @Input() public color: string;

  constructor() { }

  ngOnInit(): void {
  }

}

