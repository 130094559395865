<div class="circle-container" [ngStyle]="{
  'height': size,
  'width': size,
  'background': circleColor
}">
  <span class="initials" [ngStyle]="{
    'font-size': 'calc(' +size+ '/ 2)',
    'top': 'calc(' +size+ '/ 4)',
    'color': initialsColor
  }">
    {{initials}}
  </span>
</div>